<template>
  <VideoPopupButton
    v-if="videoId"
    :video-id="videoId"
    :is-vimeo="isVimeo"
    class="video-button"
    :aria-label="t('play-video')"
  >
    <PlayIcon class="play-icon" />
  </VideoPopupButton>
</template>

<script lang="ts" setup>
import PlayIcon from '@/assets/icons/play.svg?component'

defineProps<{
  videoId: string
  isVimeo: boolean
}>()

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.video-button {
  @include size(rem(40px));
  @include center-self;
  @include center-content;
  background: $beige;
  border-radius: 50%;

  @include media-up(md) {
    @include size(rem(50px));
  }

  @include media-up(lg) {
    @include size(rem(96px));
  }

  .contrast-mode & {
    background-color: $contrast-black;
  }
}

.play-icon {
  width: rem(10px);
  color: $dark-brown;

  @include media-up(md) {
    width: rem(12px);
    margin-left: rem(2px);
  }

  @include media-up(lg) {
    width: rem(23px);
    margin-left: rem(5px);
  }

  .contrast-mode & {
    color: $contrast-yellow;
  }
}
</style>
